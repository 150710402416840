










































import SelectWarehouse from "@/components/custom/select/SelectWarehouse.vue";
import { useLocation } from "@/hooks";
import { FormValue } from "@/hooks/useLocation";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { WarehouseResponseDto } from "@interface/warehouse";
import { FormModel } from "ant-design-vue";
import { Component, Mixins, Prop, Ref, Watch } from "vue-property-decorator";

@Component({
  components: {
    SelectWarehouse,
  },
})
export default class FormModals extends Mixins(MNotificationVue) {
  @Ref("formModel")
  formModel!: FormModel;

  @Prop({ type: Boolean, default: false, required: false })
  visible!: boolean;

  @Prop({ type: String, required: false, default: "" })
  rackId!: string;

  form: FormValue = {
    name: "",
    warehouseId: "",
    warehouseName: "",
  };

  formRules = {
    name: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    warehouseName: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
  };

  loading = false;

  get isUpdate(): boolean {
    return !!this.rackId;
  }

  get title(): string {
    const lblEdit = this.$t("lbl_edit_x", {
      x: this.$t("lbl_rack"),
    }).toString();
    const lblCreate = this.$t("lbl_create_x", {
      x: this.$t("lbl_rack"),
    }).toString();

    return this.isUpdate ? lblEdit : lblCreate;
  }

  @Watch("rackId")
  onChangeRack(newValue: string): void {
    if (!newValue) return;
    this.getDetail(newValue);
  }

  getDetail(rackId: string): void {
    const { findById, mapDetailDtoToForm } = useLocation();
    findById(rackId).then(res => {
      this.form = mapDetailDtoToForm(res);
    });
  }

  handleClose(): void {
    this.formModel.resetFields();
    this.form.warehouseId = "";
    this.$emit("close");
  }

  emitSuccess(): void {
    this.formModel.resetFields();
    this.form.warehouseId = "";
    this.$emit("success");
  }

  handleUpdate(rackId: string, form: FormValue): void {
    const { update, mapFormToCreateDto } = useLocation();
    const req = mapFormToCreateDto(form);
    this.loading = true;
    update(rackId, req)
      .then(() => {
        this.showNotifSuccess("notif_update_success", {
          documentNumber: form.name,
        });
        this.emitSuccess();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  handleCreate(form: FormValue): void {
    const { create, mapFormToCreateDto } = useLocation();
    const req = mapFormToCreateDto(form);
    this.loading = true;
    create(req)
      .then(() => {
        this.showNotifSuccess("notif_create_success", {
          documentNumber: form.name,
        });
        this.emitSuccess();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  validateForm(): void {
    this.formModel.validate((valid: boolean) => {
      if (!valid) return;

      if (this.isUpdate) {
        this.handleUpdate(this.rackId, this.form);
      } else {
        this.handleCreate(this.form);
      }
    });
  }

  onChangeWarehouse(e?: Option<WarehouseResponseDto>): void {
    this.form.warehouseName = e?.label || "";
    this.form.warehouseId = e?.meta?.id || "";
  }
}
