




































































import { FormModals } from "@/components/Rack";
import { APagination, useLocation } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { FIRST_PAGE } from "@/mixins/MQueryPage.vue";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE_SIZE,
  ONE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { WarehouseLocationResponseDto } from "@/models/interface/location";
import { SorterProps } from "@/types";
import { Component, Mixins } from "vue-property-decorator";

@Component({
  components: {
    FormModals,
  },
})
export default class IndexPage extends Mixins(MNotificationVue) {
  inputSearch = (this.$route.query?.q || "").toString();

  loading = false;

  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  dtRack: Pagination<WarehouseLocationResponseDto> = {
    currentPage: 0,
    data: [],
    totalElements: 0,
    totalPages: 0,
  };

  pagination = {
    sorts: "",
    page: +this.$route.query?.p || FIRST_PAGE,
    limit: +this.$route.query?.l || DEFAULT_PAGE_SIZE,
    reset(): void {
      this.page = FIRST_PAGE;
    },
  };

  columns = [
    {
      title: this.$t("lbl_warehouse"),
      dataIndex: "warehouse.name",
      sorter: true,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_location"),
      dataIndex: "name",
      sorter: true,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_action"),
      key: "edit",
      scopedSlots: { customRender: "edit" },
      align: "center",
    },
  ];

  modal = {
    show: false,
    rackId: "",
    toggle(): void {
      this.show = !this.show;
    },
  };

  mounted(): void {
    const params = this.buildParams();
    this.fetchData(params);
  }

  updateUrlSearch(value: Record<string, string | undefined>): void {
    this.$router.replace({
      name: "logistic.rack",
      query: {
        ...this.$route.query,
        ...value,
      },
    });
  }

  buildParams(): RequestQueryParamsModel {
    const { searchBy } = useLocation();
    const params = new RequestQueryParams();
    const { limit, page, sorts } = this.pagination;

    params.limit = limit;
    params.page = page - ONE;
    params.sorts = sorts;

    if (this.inputSearch) {
      params.search = searchBy({
        name: this.inputSearch,
        warehouseName: this.inputSearch,
      });
    }

    return params;
  }

  handleSearch(): void {
    this.pagination.reset();
    const params = this.buildParams();
    this.fetchData(params);
    this.updateUrlSearch({
      q: this.inputSearch || undefined,
      p: this.pagination.page.toString(),
    });
  }

  handleReset(): void {
    this.inputSearch = "";
    this.pagination.reset();

    const params = this.buildParams();
    this.fetchData(params);
    this.updateUrlSearch({
      q: this.inputSearch || undefined,
      p: this.pagination.page.toString(),
    });
  }

  fetchData(params?: RequestQueryParamsModel): void {
    const { findAll } = useLocation();
    this.loading = true;
    findAll(params)
      .then(res => {
        this.dtRack = res;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  handleEdit(rackId: string): void {
    this.modal.toggle();
    this.modal.rackId = rackId;
  }

  onTableChange(pagination: APagination, _filter, sorts: SorterProps): void {
    const { current, pageSize } = pagination;
    const { columnKey, order } = sorts;
    this.pagination.page = current;

    if (pageSize !== this.pagination.limit) {
      this.pagination.page = FIRST_PAGE;
    }

    this.pagination.limit = pageSize;

    if (order && order === "ascend") {
      this.pagination.sorts = `${columnKey}:asc`;
    } else if (order && order === "descend") {
      this.pagination.sorts = `${columnKey}:desc`;
    } else {
      this.pagination.sorts = "";
    }

    const params = this.buildParams();
    this.fetchData(params);
    this.updateUrlSearch({
      p: this.pagination.page.toString(),
      l: this.pagination.limit.toString(),
    });
  }

  onCloseModal(): void {
    this.modal.rackId = "";
    this.modal.toggle();
  }

  onSuccess(): void {
    this.onCloseModal();
    this.fetchData(this.buildParams());
  }
}
